import { createBrowserHistory } from 'history';
// import store from "redux/store/index";
//
// import { putState } from "redux/actions";

const history = createBrowserHistory({
  /* pass a configuration object here if needed */
});

// history.listen(location => {
// 	if (location.pathname === "/" || location.pathname === "/chart") return;
// 	store.dispatch(putState(false, "isOpenOverview"));
// });

export default history;
