import { blackColor, hexToRgb } from 'assets/jss/material-dashboard-react.js';

const tooltipStyle = {
  tooltip: {
    padding: '8px 8px',
    minWidth: '130px',
    lineHeight: '1.2em',
    border: 'none',
    borderRadius: '3px',
    boxShadow:
      '0 8px 10px 1px rgba(' +
      hexToRgb(blackColor) +
      ', 0.14), 0 3px 14px 2px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 5px 5px -3px rgba(' +
      hexToRgb(blackColor) +
      ', 0.2)',
    maxWidth: '350px',
    textAlign: 'left',
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    textShadow: 'none',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'pre-wrap',
    lineBreak: 'auto',
    'z-index': 2000,
  },
};
export default tooltipStyle;
